import { theme } from '../../../styles'
import { Title } from '../../atoms/Title'
import { DescriptionHeader } from '../DescriptionHeader'

export interface IHeaderTitleBlock {
  title: string
  subtitle: string
  currentPage?: number
}

export function HeaderTitleBlock({ title, subtitle, currentPage }: IHeaderTitleBlock): JSX.Element {
  return (
    <>
      <div className="HeaderTitleBlock flex justify-center items-center">
        <div className="HeaderTitleBlock__Content flex flex-column justify-center items-center">
          <Title color="var(--color-white)" align="center">
            {title}
          </Title>
          {(currentPage === 1 || !currentPage) && subtitle?.length ? (
            <DescriptionHeader text={subtitle} />
          ) : null}
        </div>
      </div>

      <style jsx>{`
        .HeaderTitleBlock {
          background-color: var(--color-deepBlue);
          padding: 24px;
          width: 100%;
        }
        .HeaderTitleBlock__Content {
          max-width: ${theme.breakpoints.md}px;
          background-color: var(--color-deepBlue);
          padding: 24px;
        }
      `}</style>
    </>
  )
}
