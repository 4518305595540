import { useAmp } from 'next/amp'
import { useEffect, useRef, useState } from 'react'
import { theme } from '../../../styles'
import { Icon } from '../../atoms/Icon'

export type IExpandButton = {
  children: JSX.Element
  height?: string
  maxHeight?: string
  color?: string
  backgroundColor?: string
}

export function ExpandableZone({
  children,
  height,
  color = theme.cssVars.white,
  backgroundColor = theme.cssVars.deepBlue,
}: IExpandButton): JSX.Element {
  const isAmp = useAmp()
  const overflowingElement = useRef()
  const [isOverflowing, setOverflowing] = useState(false)
  const heightElementRef = useRef(0)

  useEffect(() => {
    if (overflowingElement.current) {
      const resizeObserver = new ResizeObserver(() => {
        const element: any = overflowingElement.current
        if (heightElementRef.current !== element.offsetHeight)
          setOverflowing(element.offsetHeight < element.scrollHeight)
        heightElementRef.current = element.scrollHeight
      })

      resizeObserver.observe(overflowingElement.current)

      return () => {
        if (overflowingElement?.current) {
          resizeObserver?.unobserve?.(overflowingElement.current)
        }
      }
    }
  }, [])

  return (
    <>
      <div className={`ExpandableZone${isAmp ? ' ExpandableZone__isAmp' : ''}`}>
        <input type="checkbox" id="ExpandedInput" />
        <div className="ExpandableZone__Content" ref={overflowingElement}>
          {children}
        </div>
        {isOverflowing && (
          <div className="ExpandableZone__Expands">
            <div className="ExpandableZone__Blur" />
            <div className="ExpandableZone__Button flex">
              <label
                className="ExpandableZone__Button__Label flex items-center"
                htmlFor="ExpandedInput"
              >
                <span className="ExpandableZone__Button__text__off">
                  Replier
                  <Icon name="chevron" orientation="up" primaryColor={color} />
                </span>
                <span className="ExpandableZone__Button__text__on">
                  Lire plus
                  <Icon name="chevron" orientation="down" primaryColor={color} />
                </span>
              </label>
            </div>
          </div>
        )}
      </div>
      <style jsx>
        {`
          .ExpandableZone__Content {
            max-height: ${height};
          }
        `}
      </style>

      <style jsx>
        {`
          .ExpandableZone {
            width: 100%;
            position: relative;
          }

          .ExpandableZone__Content {
            width: 100%;
            overflow: hidden;
          }

          .ExpandableZone__Expands {
            bottom: 0;
            width: 100%;
            z-index: 1;
            height: 40px;
            position: relative;
          }

          .ExpandableZone__Button {
            bottom: 0;
            width: 100%;
            height: 40px;
            position: absolute;

            font-family: 'Overpass';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;

            background-color: ${backgroundColor};
            color: ${color};
          }

          .ExpandableZone__Blur {
            position: absolute;
            background: linear-gradient(180deg, ${backgroundColor} 0%, transparent 100%);
            transform: rotate(-180deg);
            height: 50px;
            width: 100%;
            top: -50px;
            display: block;
          }

          .ExpandableZone__Button__Label {
            margin: auto;
            cursor: pointer;
          }

          #ExpandedInput:checked ~ .ExpandableZone__Content,
          .ExpandableZone__isAmp .ExpandableZone__Content {
            height: auto;
            max-height: initial;
          }

          #ExpandedInput,
          .ExpandableZone__isAmp .ExpandableZone__Button,
          #ExpandedInput:checked ~ .ExpandableZone__Expands > .ExpandableZone__Blur,
          .ExpandableZone__Button__text__off,
          #ExpandedInput:checked ~ .ExpandableZone__Expands .ExpandableZone__Button__text__on {
            display: none;
          }

          .ExpandableZone__Button__text__on,
          #ExpandedInput:checked ~ .ExpandableZone__Expands .ExpandableZone__Button__text__off {
            display: flex;
            align-items: center;
          }
        `}
      </style>
    </>
  )
}
